import React from 'react';
import './Main.css';
import { Link } from 'react-router-dom';
import { HomeMenuBar } from '../../Components/MenuBarForHome/MenuBarHome';


const Main = () => {

    return (
        <>


            <HomeMenuBar />
            <div className="m-wrapper">

                <div className="m-bottom">
                    <h1>adaptive</h1>
                    <h2>to your business, your growth & the future.</h2>
                    <h3>
                        Proudly serving the world's top asset finance & leasing
                        companies with smart software technology for over four decades.
                    </h3>

                    <Link to='/explore#test' smooth>
                        <button className='circle-btn m-btn'>Explore</button>
                    </Link>

                </div>

                <div className="m-right">
                    <select>
                        <option >English</option>
                        <option>Chiness</option>
                        <option>Thai</option>
                        <option>Bahasa</option>
                    </select>
                </div>
            </div>

        </>
    )
}

export default Main