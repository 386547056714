import React from 'react'
import { useState } from 'react';
// Css
import './About.css';
// Images 
import Vector1 from '../../img/vector1.svg';
import Vector2 from '../../img/vector2.svg';
import Vector3 from '../../img/vector3.svg';
import Vector4 from '../../img/vector4.svg';
import Vector5 from '../../img/vector5.svg';
import Hasnain from '../../img/hasnain.jpg';
import Human1 from '../../img/human1.jpg';
import Human2 from '../../img/human2.jpg';
import Ceremony1 from '../../img/cemany1.jpg';
import Ceremony2 from '../../img/cemany2.jpg';
import Ceremony3 from '../../img/cemany3.jpg';
import Add from '../../img/add.svg';
import NETSOL from '../../img/netsolVector.svg'
// Icons
import { FaLinkedinIn } from 'react-icons/fa';
// Pages
import Dated from '../../Components/Dated/Dated';
import Demo from '../../Components/Demo/Demo';
import Footer from '../../Components/Footer/Footer';
import { MenuBar } from '../../Components/MenuBar/MenuBar';
// Depen
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AboutBox } from './AboutData/AboutData';

const About = () => {

    const [addDir, setAddDir] = useState(false);
    const [addTeam, setAddTeam] = useState(false);

    const [aboutData, setAboutData] = useState(AboutBox[0]);


    return (
        <>
            <MenuBar />
            <Demo />

            <div className="about-wrapper">
                <div className="container">
                    <div className="viewing">
                        You are now viewing <span className='viewing-span'><Link to='/'>Home</Link></span>  / <span className='viewing-span'><Link to='/about'>About Us</Link></span>
                    </div>

                    <div className="about-header">



                        <h1>About Us <img src={NETSOL} alt="" /></h1>
                        <h5>
                            Proudly serving the world’s top asset finance
                            and leasing companies with smart software technology
                            for over four decades.
                        </h5>
                        <button className='btn about-btn1'>Download Profile</button>
                        <button className='btn about-btn2'>Get in Touch</button>
                    </div>

                    <div className="about-box1">
                        <img src={aboutData.img} alt="" />

                        <div className="about-b-title">
                            <h1>{aboutData.title}<span style={{ color: '#1c72b8' }}></span></h1>
                            <motion.h6
                                initial={{ scale: 0 }}
                                whileInView={{ scale: 1 }}
                                transition={{ duration: 0.2, type: 'liner', stiffness: 120 }}
                            >
                                {aboutData.desc}
                            </motion.h6>
                        </div>

                        <div className="viewing-icon">
                            <img src={Vector3}
                                onClick={() => setAboutData(AboutBox[0])} alt="" />
                            <img src={Vector1} onClick={() => setAboutData(AboutBox[1])} alt="" />
                            <img src={Vector5} onClick={() => setAboutData(AboutBox[2])} alt="" />
                            <img src={Vector4} onClick={() => setAboutData(AboutBox[3])} alt="" />
                            <img src={Vector2} onClick={() => setAboutData(AboutBox[4])} alt="" />
                        </div>
                    </div>




                </div>
            </div>
            <div className="a-experience">
                <div className="experience-wrapper">

                    <div className="exper-l">
                        <motion.h1
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.5, type: 'liner' }}
                        >Over Four Decades of Global Experience</motion.h1>
                        <motion.h6
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.6, type: 'liner' }}
                        >
                            With over forty years of experience in providing
                            state-of-the-art solutions, cost-effective
                            capabilities and consulting services to the global
                            finance and leasing industry, we cater to companies
                            with ever growing and diverse business requirements
                            across the world.
                        </motion.h6>
                    </div>
                    <div className="exper-r">

                        <motion.div className="exper-circle1"
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.5, type: 'liner' }}
                        >
                            <div className="circle">
                                <h1>40+</h1>
                                <h3>Years in the Americas</h3>
                            </div>
                        </motion.div>

                        <motion.div className="exper-circle1"
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.6, type: 'liner' }}
                        >
                            <div className="circle">
                                <h1>25+</h1>
                                <h3>Years in Europe</h3>
                            </div>
                        </motion.div>

                        <motion.div className="exper-circle1"
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.7, type: 'liner' }}
                        >
                            <div className="circle">
                                <h1>20+</h1>
                                <h3>Years in the Asia Pacific</h3>
                            </div>
                        </motion.div>


                    </div>
                </div>
            </div>

            <div className="edge">
                <motion.div className="edge-heading"
                    initial={{ opacity: 0, visibility: 'hidden' }}
                    whileInView={{ opacity: 100, visibility: 'visible' }}
                    transition={{ duration: 1, type: 'liner' }}
                >
                    <h1>Our Edge</h1>
                </motion.div>

                <div className="edge-top">
                    <div className="edge-box">
                        <motion.h2
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.6, type: 'liner' }}
                        >Proven Track Record</motion.h2>
                        <motion.h6
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.7, type: 'liner' }}
                        >
                            NETSOL Technologies taps into its strong business
                            acumen to find solutions to the unique set of
                            challenges and constraints imposed by each new project
                            and delivers solutions that fill performance gaps.
                            We have a proven track record of successfully meeting
                            deadlines and executing the most complex projects
                            within budget while consistently maintaining the
                            highest quality.
                        </motion.h6>
                    </div>

                    <div className="edge-box">
                        <motion.h2
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.5, type: 'liner' }}
                        >Domain Expertise</motion.h2>
                        <motion.h6
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.7, type: 'liner' }}
                        >
                            With our rich history of innovation, NETSOL Technologies
                            is a dynamic leader and has been able to accumulate a wealth
                            of experience in the global asset finance and leasing industry
                            . We have built a large knowledge base which is regularly
                            refined and updated to ensure the most up-to-date best practices
                            and business solutions for the benefit of our clients and partners.
                        </motion.h6>
                    </div>

                </div>


                <div className="edge-down">
                    <div className="edge-box">
                        <motion.h2
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.5, type: 'liner' }}
                        >Ensured Service Levels</motion.h2>
                        <motion.h6
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.7, type: 'liner' }}
                        >
                            With a presence in 8 strategically located cities across
                            the globe, and a team of more than 1300 professionals,
                            we ensure that our clients get dedicated attention from
                            the best minds in the industry. We boast an impressive
                            portfolio of several quality awards which further solidify
                            our dominance and status as the finest global service
                            providers.
                        </motion.h6>
                    </div>

                    <div className="edge-box">
                        <motion.h2
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.5, type: 'liner' }}
                        >Pioneers in the Industry</motion.h2>
                        <motion.h6
                            initial={{ y: 100, visibility: 'hidden' }}
                            whileInView={{ y: 0, visibility: 'visible' }}
                            transition={{ duration: 0.7, type: 'liner' }}
                        >
                            NETSOL Technologies maintains its position as a leader
                            in providing innovative solutions to the global asset
                            finance and leasing industry. We take pride in being
                            the first organization in the industry to introduce
                            digital transformation and launch a complete line of
                            digital solutions. We introduced our digital suite to
                            help companies tap into the advantages of digitization
                            and mobility while taking control of the challenges
                            inherent in this environment.
                        </motion.h6>
                    </div>

                </div>
            </div>

            <div className="directors">

                <div className="director-heading">
                    <h1>Board of Directors</h1>
                </div>

                <div className="dir-box">

                    <div className="director-card">
                        <div className="dir-img">
                            <img src={Hasnain} alt="" />
                        </div>
                        <div className="dir-title">
                            <h2>Hasnain Irfan</h2>
                            <h4>
                                Founder, Chairman & Chief Executive
                                Officer, NETSOL Technologies, Inc.
                            </h4>
                        </div>
                        <div className='dir-button'>
                            <button className='btn dir-btn'>Profile</button>
                            <span className='dir-link'><FaLinkedinIn /></span>
                        </div>
                    </div>

                    <div className="director-card">
                        <div className="dir-img">
                            <img src={Human2} alt="" />
                        </div>
                        <div className="dir-title">
                            <h2>Nihal</h2>
                            <h4>
                                Independent Director, Chairman of Audit Committee
                            </h4>
                        </div>
                        <div className='dir-button'>
                            <button className='btn dir-btn'>Profile</button>
                            <span className='dir-link'><FaLinkedinIn /></span>
                        </div>
                    </div>

                    <div className="director-card">
                        <div className="dir-img">
                            <img src={Human1} alt="" />
                        </div>
                        <div className="dir-title">
                            <h2>Shoaib </h2>
                            <h4>
                                Independent Director & Chair of Compensation Committee
                            </h4>
                        </div>
                        <div className='dir-button'>
                            <button className='btn dir-btn'>Profile</button>
                            <span className='dir-link'><FaLinkedinIn /></span>
                        </div>
                    </div>
                </div>

                {
                    addDir ?
                        <div className="dir-box">

                            <div className="director-card">
                                <div className="dir-img">
                                    <img src={Hasnain} alt="" />
                                </div>
                                <div className="dir-title">
                                    <h2>Hasnain Irfan</h2>
                                    <h4>
                                        Founder, Chairman & Chief Executive
                                        Officer, NETSOL Technologies, Inc.
                                    </h4>
                                </div>
                                <div className='dir-button'>
                                    <button className='btn dir-btn'>Profile</button>
                                    <span className='dir-link'><FaLinkedinIn /></span>
                                </div>
                            </div>

                            <div className="director-card">
                                <div className="dir-img">
                                    <img src={Human2} alt="" />
                                </div>
                                <div className="dir-title">
                                    <h2>Syed Kausar Kazmi</h2>
                                    <h4>
                                        Independent Director, Chairman of Audit Committee
                                    </h4>
                                </div>
                                <div className='dir-button'>
                                    <button className='btn dir-btn'>Profile</button>
                                    <span className='dir-link'><FaLinkedinIn /></span>
                                </div>
                            </div>
                        </div>
                        : null
                }

                <div className="dir-add">
                    <img src={Add} alt="" onClick={() => setAddDir(true)} />
                </div>



            </div>

            <div className="managment-team">

                <div className="team-heading">
                    <h1>Management Team</h1>
                </div>

                <div className="team-boxs">
                    <div className="team-box">
                        <div>
                            <div className="tb-title">
                                <h3>Hasnain Irfan</h3>
                            </div>
                            <div className="tb-desc">
                                <h5>Founder, Chairman & Chief Executive Officer, NETSOL Technologies, Inc.</h5>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>

                    <div className="team-box">
                        <div>
                            <div className="tb-title">
                                <h3>Naeem Ghauri</h3>
                            </div>
                            <div className="tb-desc">
                                <h5>Founder, President, Chief Executive Officer Innovation and OTOZ, NETSOL Technologies, Inc.</h5>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>

                    <div className="team-box">
                        <div>
                            <div className="tb-title">
                                <h3>Salim Ghauri</h3>
                            </div>
                            <div className="tb-desc">
                                <h5>Founder & Chief Executive Officer, NETSOL Technologies Ltd. Pakistan</h5>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>
                </div>

                {
                    addTeam ?
                        <>

                            <div className="team-boxs">
                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Hasnain Irfan</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, Chairman & Chief Executive Officer, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Naeem Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, President, Chief Executive Officer Innovation and OTOZ, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Salim Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder & Chief Executive Officer, NETSOL Technologies Ltd. Pakistan</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>
                            </div>

                            <div className="team-boxs">
                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Hasnain Irfan</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, Chairman & Chief Executive Officer, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Naeem Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, President, Chief Executive Officer Innovation and OTOZ, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Salim Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder & Chief Executive Officer, NETSOL Technologies Ltd. Pakistan</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>
                            </div>

                            <div className="team-boxs">
                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Hasnain Irfan</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, Chairman & Chief Executive Officer, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Naeem Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, President, Chief Executive Officer Innovation and OTOZ, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>

                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Salim Ghauri</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder & Chief Executive Officer, NETSOL Technologies Ltd. Pakistan</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>
                            </div>

                            <div className="team-boxs">
                                <div className="team-box">
                                    <div>
                                        <div className="tb-title">
                                            <h3>Hasnain Irfan</h3>
                                        </div>
                                        <div className="tb-desc">
                                            <h5>Founder, Chairman & Chief Executive Officer, NETSOL Technologies, Inc.</h5>
                                        </div>
                                    </div>
                                    <div className="tb-btns">
                                        <button className="btn tb-btn">Profile</button>
                                    </div>
                                </div>



                            </div>
                        </>
                        : null
                }

                <div className="team-btn">
                    <img src={Add} alt="" onClick={() => setAddTeam(true)} />
                </div>

            </div>

            {/*  */}

            <div className="managment-team">

                <div className="team-heading">
                    <h1>Advisory Board</h1>
                </div>

                <div className="team-boxs">

                    <div className="team-box">
                        <div>
                            <div className="tb-title">
                                <h3>Georg Bauer</h3>
                            </div>
                            <div className="tb-desc">
                                <h5>Chairman and Co-founder of Fair.com</h5>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ceremony">
                <div className="ceremony-title">
                    <h1>Nasdaq Closing Bell Ceremony</h1>
                </div>
                <div className="ceremony-dec">
                    <h3>
                        NETSOL rings closing bell to commemorate its
                        20th listing anniversary at Nasdaq.
                    </h3>
                </div>
                <div className="cermony-img">
                    <img src={Ceremony1} alt="" />
                    <img src={Ceremony2} alt="" />
                    <img src={Ceremony3} alt="" />
                </div>
            </div>

            <div className="Corporate">
                <div className="ceremony-title">
                    <h1>Corporate Governance</h1>
                </div>
                <div className="ceremony-dec">
                    <h3>
                        We take our corporate governance, ethics
                        and policies seriously. For any harassment
                        reporting, please call (888) 571-8220 or email
                        us at corporategovernance@netsoltech.com
                    </h3>
                </div>

                <div className="team-boxs">
                    <div className="team-box">
                        <div>
                            <div className="tb-title cb-title">
                                <h3>Amended and Restated-Code of Ethics</h3>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>

                    <div className="team-box">
                        <div>
                            <div className="tb-title cb-title">
                                <h3>Amended and Restated-Audit Committee Charter</h3>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>

                    <div className="team-box">
                        <div>
                            <div className="tb-title cb-title">
                                <h3>
                                    Amended and Restated Charter of the
                                    Nominating and Corporate Governance Committee
                                    of nominating committee charter
                                </h3>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>
                </div>

                <div className="team-boxs">
                    <div className="team-box">
                        <div>
                            <div className="tb-title cb-title">
                                <h3>Amended and Restated Charter of the Compensation Committee</h3>
                            </div>
                        </div>
                        <div className="tb-btns">
                            <button className="btn tb-btn">Profile</button>
                        </div>
                    </div>
                </div>

            </div>


            <div className="social-img">
                <div className='socialBlance'>
                    <div className="social-header">
                        <h3>CORPORATE AND SOCIAL RESPONSIBILITIES</h3>
                    </div>
                    <div className="social-title">
                        <h1>We all want a better future. Together, we can make a difference.</h1>
                    </div>
                    <div className="social-dec">
                        <motion.h6>
                            Our commitment towards innovation and providing greater services
                            to our customers is second to none. This philosophy of excelling
                            in everything we do has enabled us to not only fulfill our environmental
                            sense of responsibility, but go further beyond. We acknowledge global
                            climate change as a real problem, and as a borderless company, we pledge
                            to do everything in our capacity to tackle the problem. To that end,
                            we ensure that we eliminate wasteful practices and make all our processes
                            as energy efficient as possible.
                        </motion.h6>
                    </div>

                    <div className="social-btn">
                        <button className="btn sbtn">Download Profile</button>
                    </div>
                </div>

            </div>

            <Dated />
            <Footer />
        </>
    )
}

export default About