import React from 'react';
import './Demo.css';
import DEMO from '../../img/requestDemo.svg';
import { useState } from 'react';

const Demo = () => {
  const [demo, setDemo] = useState(false);
  return (
    <>
      <div className="demo">
        <div className="demo-main">
          <img onClick={() => setDemo(true)} src={DEMO} alt="" />
        </div>
      </div>

      {
        demo ?
          <div className="demo-bigbox">
            <div className="demo-box">
              <div className="demo-close">
                <span onClick={()=>setDemo(false)}>x</span>
              </div>
              <div className="cf-heading demo-heading">
                <h1>Request Demo </h1>
                <h2><span className='abc'>01</span>.Enter your basic information</h2>
              </div>
              <div className="demo-items ">
                <form action="">
                  <input type="text" placeholder='Name' />
                  <input type="email" placeholder='Email' />
                  <input type="text" placeholder='Designation' />
                  <input type="text" placeholder='Company Name' />
                  <select>
                    <option>Pakistan</option>
                    <option>India</option>
                    <option>Afganistan</option>
                    <option>China</option>
                  </select>
                  <input type="text" placeholder='Phone' />

                  <div className="contact-btn">
                    <button className="btn">PREVIOUS</button>
                    <button className="btn">NEXT</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          : null
      }


    </>


  )
}

export default Demo