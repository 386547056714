import React from 'react';
import './Explore.css';
import Twitter from '../../img/Twitter.svg';
import Releases from '../../img/Releases.svg';
import Events from '../../img/Events.svg';
import { MenuBar } from '../MenuBar/MenuBar';
import { motion } from 'framer-motion';


const Explore = () => {
    return (
        <>
        <motion.div 
         initial={{ y: 200 }}
         animate={{ y: 0 }}
         transition={{ duration: 0.2, type: 'liner', stiffness: 120 }}
        >
            <MenuBar ></MenuBar>
        </motion.div>
            <motion.div className="explore-wrapper"
                initial={{ y: 200 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.2, type: 'liner', stiffness: 120 }}
            >
                <div className="explore-l">
                    <div className="e-title">
                        <h2>Welcome to the <span style={{ color: '#1c72b8' }}>NETSOL</span>Tech SmartNav Wizard.</h2>
                        <h4>Lets quickly get to the information you require.</h4>
                    </div>

                    <div className="e-chat">
                        <span>What would you like to see?</span>
                    </div>

                    <div className="explore-btn">
                        <div className="el-btn">
                            <button className='e-btn'>About Netsol</button>
                            <button className='e-btn'>Products</button>
                            <button className='e-btn'>Innovation</button>
                            <button className='e-btn'>Investor Relations</button>
                            <button className='e-btn'>Events</button>
                        </div>
                        <div className="er-btn">
                            <button className='ed-btn'>Menu</button>
                            <button className='ed-btn'>Contact Us</button>
                        </div>
                    </div>

                </div>
                <div className="explore-r" id='test'>
                    <div className="er-mainBox">

                        <div className="er-box">
                            <div className="er-heading">
                                <div className='er-title'>
                                    <h2>Press Releases</h2>
                                    <h4>Released Jun 17, 2021</h4>

                                </div>
                                <div className='er-img'>
                                    <img src={Releases} alt="" />
                                </div>

                            </div>
                            <h6>
                                MINI USA and MINI Financial Services
                                Enable Dealers to Facilitate End-to-End
                                Online Shopping Experience with Launch of
                                MINI Anywhere Online Retail Platform
                            </h6>
                            <div className='er-btnleft'>

                                <button className='btn left-btn'>Explore</button>
                            </div>
                        </div>

                        <div className="er-box">
                            <div className="er-heading">
                                <div className='er-title'>
                                    <h2>From Twitter</h2>
                                    <h4>Latest tweet from @netsoltech</h4>

                                </div>
                                <div className='er-img'>
                                    <img src={Twitter} alt="" />
                                </div>

                            </div>
                            <h6>
                                ntroducing NXT: a COVID-Aware Smart
                                Workplace Returning to your offices will
                                now be safe and fulfilling while ensuring

                            </h6>
                            <div className='er-btnleft'>

                                <button className='btn left-btn'>Explore</button>
                            </div>
                        </div>

                        <div className="er-box" style={{ border: "none" }}>
                            <div className="er-heading">
                                <div className='er-title'>
                                    <h2>Events</h2>
                                    <h4>Where you can find us</h4>

                                </div>
                                <div className='er-img'>
                                    <img src={Events} alt="" />
                                </div>

                            </div>
                            <h6>
                                Upcoming Event: Motor Finance Europe:
                                Conference & Awards 2022 On 20-10-2022
                            </h6>
                            <div className='er-btnleft'>

                                <button className='btn left-btn'>Explore</button>
                            </div>
                        </div>


                    </div>
                </div>

            </motion.div>



        </>
    )
}

export default Explore