import React from 'react';
import './MenuBar.css';
import Logo from '../../img/bluelogo.svg';
import { Link } from 'react-router-dom';
import BackToTopButton from '../BackTotop/BackToTop';


export const MenuBar = () => {

    return (
        <>
            <div className="menuBar">
                <div className="menuLogo">
                    <Link to='/'>
                        <img src={Logo} width={50} height={50} alt="" />
                    </Link>
                </div>
                <div className="main_M">
                    <h1>menu</h1>
                    {/* <span onClick={() => setMenu(true)}><GoThreeBars size={40} /></span> */}
                    
                    <div className="navbar_icon" >
                        <div className='menu1'></div>
                        <div className='menu2'></div>
                        <div className='menu3'></div>
                    </div>
                </div>

                <div>
                    <BackToTopButton />
                </div>

            </div>


        </>
    )

}




// const [menu, setMenu] = useState('navbar_icon unclicked');
// const [menu_class, setMenu_class] = useState('nav hiddin');
// const [isMenuClicked, setIsMenuClicked] = useState(false);


// const updateMenu = () => {
//     if (!isMenuClicked) {
//         setMenu("navbar_icon clicked")
//         setMenu_class("nav visible")
//     }
//     else {
//         setMenu("navbar_icon unclicked")
//         setMenu_class("nav hidden")
//     }
//     setIsMenuClicked(!isMenuClicked)

// }