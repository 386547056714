import React from 'react';
import './Footer.css';
import { FaLinkedinIn } from 'react-icons/fa';
import { ImTwitter } from 'react-icons/im';

const Footer = () => {
  return (
    <div className="footer-wrapper">

      <div className="footer-top">

        <div className="footer-top1" style={{ width: "25%" }}>
          <div className="footer-heading"><h2>CONTACT US</h2></div>
          <div className="footer-items">
            <ul>
              <li>Corporate Headquarters</li>
              <li>23975 Park Sorrento</li>
              <li>Suite 250</li>
              <li>Calabasas, CA 91302</li>
              <li>USA</li>
              <li>+1 818 222 9195</li>
              <li>info@netsoltech.com</li>
            </ul>
          </div>
        </div>

        <div className="footer-top1">
          <div className="footer-heading"><h2>COMPANY</h2></div>
          <div className="footer-items">
            <ul>
              <li>About Us</li>
              <li>Board of Directors</li>
              <li>Management Team</li>
              <li>Social Responsibilities</li>
            </ul>
          </div>
        </div>

        <div className="footer-top1">
          <div className="footer-heading"><h2>NFS ASCENT®</h2></div>
          <div className="footer-items">
            <ul>
              <li>Front Office</li>
              <li>Back Office</li>
              <li>Self Service</li>
            </ul>
          </div>
        </div>

        <div className="footer-top1" style={{ width: "25%" }}>
          <div className="footer-heading"><h2>NFS DIGITAL</h2></div>
          <div className="footer-items">
            <ul>
              <li>Self Point of Sale</li>
              <li>Mobile Account</li>
              <li>Mobile Point of Sale</li>
              <li>Web Point of Sale</li>
              <li>Mobile Field Investigator</li>
              <li>Mobile Collector</li>
              <li>Mobile Dealer</li>
              <li>Mobile Auditor</li>
            </ul>
          </div>
        </div>

      </div>

      <div className="footer-top">

        <div className="footer-top1" style={{ width: "25%" }}>
          <div className="footer-heading"><h2>OFFICES</h2></div>
          <div className="footer-items">
            <ul>
              <li>Calabasas <span>+1 818 222 9195</span></li>
              <li>London <span>+44 2 0398 10290</span></li>
              <li>Sydney <span>+61 2 9159 0165</span></li>
              <li>Beijing <span>+86 10 6568 2256</span></li>
              <li>Shanghai <span>+86 21 6020 6700</span></li>
              <li>Bangkok <span>+66 2685 3552 3</span></li>
              <li>Jakarta <span>+62 21 2965 5859</span></li>
              <li>Lahore <span>+92 42 111 44 88 00</span></li>
            </ul>
          </div>
        </div>

        <div className="footer-top1">
          <div className="footer-heading"><h2>INVESTORS</h2></div>
          <div className="footer-items">
            <ul>
              <li>Overview</li>
              <li>Company Information</li>
              <li>News</li>
              <li>Stock Data</li>
              <li>SEC Filings</li>
            </ul>
          </div>
        </div>

        <div className="footer-top1">
          <div className="footer-heading"><h2>INNOVATION</h2></div>
          <div className="footer-items">
            <ul>
              <li>Overview</li>
              <li>Articles</li>
              <li>Downloads</li>
              <li>In the Lab</li>
            </ul>
          </div>
        </div>

        <div className="footer-top1" style={{ width: "25%" }}>
          <div className="footer-heading"><h2>FOLLOW</h2></div>
          <div className="footer-items">
            <div className='footer-icons'>
              <div>
                <h1>Twitter </h1><span><ImTwitter /></span>
              </div>
              <div>
                <h1>LinkedIn </h1><span><FaLinkedinIn /></span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="footer-down">
        <div className="footerDownLine">
          <div className="footer-title">
            <div className="footerDown-left">
              <h2>© 2022 NETSOL Technologies. All Rights Reserved.</h2>
            </div>
            <div className="footerDown-right">
              <h3>
                Terms of Use | Privacy Policy | Human Rights Policy | Modern Slavery Act
              </h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Footer