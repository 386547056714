import React from 'react';
import './Product.css';
import Working1 from '../../img/working1.svg';
import Working2 from '../../img/working2.svg';
import Working3 from '../../img/working3.svg';
import Working4 from '../../img/working4.svg';
import NFS from '../../img/NFS-Ascent.svg';
import AscentVector from '../../img/ascentVector.svg';
import NFSDIGTAL from '../../img/NFS-Digtal.svg';
import DigitalVector from '../../img/digitalVector.svg';
import { MenuBar } from '../../Components/MenuBar/MenuBar';
import Dated from '../../Components/Dated/Dated';
import Footer from '../../Components/Footer/Footer';
import Demo from '../../Components/Demo/Demo';
import { Link } from 'react-router-dom';

const Product = () => {
    return (

        <>
            <MenuBar />
            <Demo />

            <div className="Product-wrapper">
                <div className="viewing">
                    You are now viewing <span className='viewing-span'><Link to='/'>Home</Link></span>  / <span className='viewing-span'><Link to='/product'>Products</Link></span>
                </div>
                <div className="p-topBox">

                    <div className="p-topBox-left">
                        <div className="p-topBox-icon">
                            <img src={NFS} alt="" />
                        </div>
                        <div className='productbg'>
                            <img src={AscentVector} alt="" />
                        </div>
                        <div className="p-topBox-title">
                            <h3>
                                An Adaptive Platform for
                                the Evolving Global Asset
                                Finance and Leasing Industry
                            </h3>
                        </div>
                        <div className="p-topBox-dec">
                            <h6>
                                NFS Ascent® is built on cutting-edge,
                                modern technology that enables auto,
                                equipment and big ticket finance
                                companies to run their retail and
                                wholesale finance business with ease.
                                With comprehensive domain coverage and
                                powerful configuration engines, it is
                                architected to empower finance and leasing
                                companies with a platform that supports
                                their growth in terms of business volume
                                and transactions.
                            </h6>
                        </div>
                        <div className="p-topBox-btn">
                            <button className="btn">Explore</button>
                            <button className="btn">Request demo</button>
                        </div>

                    </div>
                    <div className="p-topBox-right">
                        <div className="p-topBox-img">

                            <img src={Working1} alt="" /><span>AUTO FINANCE</span>
                            <img src={Working3} alt="" /><span>EQUIPMENT FINANCE</span>
                            <img src={Working2} alt="" /><span>BIG TICKET FINANCE</span>
                            <img src={Working4} alt="" /><span>BANKS</span>
                        </div>
                    </div>
                </div>



                <div className="p-topBox" >

                    <div className="p-topBox-left">
                        <div className="p-topBox-icon">
                            <img src={NFSDIGTAL} alt="" />
                        </div>
                        <div className='productbg'>
                            <img src={DigitalVector} alt="" />
                        </div>
                        <div className="p-topBox-title">
                            <h3>
                                Curated Digital Touchpoints for
                                Your Teams, Partners & Customers
                            </h3>
                        </div>
                        <div className="p-topBox-dec">
                            <h6 style={{ marginBottom: '10px' }}>
                                NFS Digital is a combination of our core
                                strengths, domain and technology. Our
                                insight into the evolving landscape along
                                with our valuable experience enables us
                                to define sound digital transformation
                                strategies and compliment them with smart
                                digital solutions so our customers always
                                remain competitive and relevant to the dynamic
                                environment. Our digital transformation
                                solutions are extremely robust and can be
                                used with or without our core, next-gen
                                solution (NFS Ascent®) to effectively
                                augment and enhance your ecosystem.
                            </h6>
                        </div>
                        <div className="p-topBox-btn">
                            <button className="btn">Explore</button>
                            <button className="btn">Request demo</button>
                        </div>
                    </div>
                    <div className="p-topBox-right">
                    </div>
                </div>

            </div>
            <Dated />
            <Footer />
        </>
    )
}

export default Product