import React from 'react';
import { MenuBar } from '../../Components/MenuBar/MenuBar';
import EVENTBG from '../../img/events-vector.svg';
import EVENT1 from '../../img/Event1.png';
import './Events.css'
import Demo from '../../Components/Demo/Demo';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';

const Events = () => {
    return (
        <>
            <MenuBar />
            <Demo />
            <div className="events-wrapper">
                <div className="viewing">
                    You are now viewing <span className='viewing-span'><Link to='/'>Home</Link></span>  / <span className='viewing-span'><Link to='/events'>Events</Link></span>
                </div>
                <div className='eventbg'>
                    <img src={EVENTBG} alt="" />
                </div>
                <div className="about-header event-header">
                    <h1>Events</h1>
                    <h5>
                        To maintain our dominance in the industry,
                        every year we attend and sponsor international
                        finance and leasing summits, conferences,
                        conventions and events worldwide
                    </h5>
                </div>

                <div className="event-boxs">
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="event-boxs">
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="event-boxs">
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                    <div className="event-box">
                        <div className="eb-img">
                            <img src={EVENT1} alt="" />
                        </div>
                        <div className="eb-title">
                            <h2>Motor Finance Europe: Conference & Awards 2022</h2>
                        </div>
                        <div className="eb-desc">
                            <div className="eb-desc1">
                                <h4>Location</h4>
                                <h6>The Westin Grand Munich</h6>
                            </div>
                            <div className="eb-desc2">
                                <h4>Date</h4>
                                <h6>20th October 2022</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Events