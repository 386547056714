import React, { useState } from 'react';
import './MenuBarHome.css';
import Logo from '../../img/logo.svg';
import { GoThreeBars } from 'react-icons/go';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';

export const HomeMenuBar = () => {
    const [menu, setMenu] = useState(false);

    return (
        <>
            <div className="menuBarHome">
                <div className="menuLogo1">
                    <Link to='/'>
                    <img src={Logo} width={50} height={50} alt="" />
                    </Link>
                </div>
                <div className="main_MM">
                    <h1>MENU</h1>
                    <span onClick={() => setMenu(true)}><GoThreeBars size={40} /></span>
                </div>
            </div>
            {
                menu ? <Navbar setMenu={setMenu} /> : null
            }
        </>
    )
}
