import AboutImg1 from '../../../img/aboutMainBoxBg.svg';
import AboutImg2 from '../../../img/aboutLeadershipBg.svg';
import AboutImg3 from '../../../img/aboutSoftwareBg.svg';
import AboutImg4 from '../../../img/aboutPeopleBg.svg';
import AboutImg5 from '../../../img/aboutMethodologiesBg.svg';

export const AboutBox = [
    {
        img: AboutImg1,
        title: "We are an Adaptive Enterprise",
        desc: "From when we started to where we are now, NETSOL has been progressing as an enterprise that leads change."
    },
    {
        img: AboutImg2,
        title: "Leadership",
        desc: "Our leadership is focused on developing a culture of innovation and empowerment to create an environment where people are driven and focused on achieving excellence."
    },
    {
        img: AboutImg3,
        title: "Software",
        desc: "Our smart software solutions are built with smart work engines that adapt to our clients' business, optimizes their value delivery and gives them the freedom to evolve."
    },
    {
        img: AboutImg4,
        title: "People",
        desc: "We have a young, diverse and experienced global workforce who provide an influx of energy and fresh thought to the enterprise."
    },
    {
        img: AboutImg5,
        title: "Methodologies",
        desc: "We connect the dots to suggest what our customers imperatives and alternatives might be to achieve the best outcome."
    }
]