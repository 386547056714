import Vectorascent from '../../../img/Vectorascent.svg';
import Vectorevent from '../../../img/Vectorevent.svg';
import Vectorinnovation from '../../../img/Vectorinnovation.svg';
import Vectorinvestors from '../../../img/Vectorinvestors.svg';
import NETSOLE from '../../../img/netsolVector.svg';

export const Images = [
    NETSOLE,
    Vectorascent,
    Vectorinnovation,
    Vectorinvestors,
    Vectorevent
]