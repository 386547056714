import React from 'react';
import './Navbar.css';
import Logo from '../../img/logo.svg';
import { GiCrossMark } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { Images } from './NavbarData/Navbarimg';
import { useState } from 'react';



const Navbar = (props) => {

    const [selectNavImg, setSelectNavImg] = useState(Images[0]);
    return (
        <>
            <div className="n_wrapper">

                <div className="n-top">
                    <div className="n-logo">
                        <Link to='/'>
                            <img src={Logo} width={50} height={50} alt="" />
                        </Link>
                    </div>
                    <div className="n-btn-right">
                        <button className='btn'>Careers</button>
                        <Link to='/contact'><button className='btn'>Contact us</button></Link>
                    </div>
                </div>

                <div className="n-bottom">
                    <div className="n-menu-btn" onClick={() => props.setMenu(false)}>
                        <h1>close</h1>
                        <GiCrossMark />
                    </div>

                    <div className="navbar-bgVector">
                            <img src={selectNavImg} alt='' />
                        {/* {
                            Images.map((item, index) => {
                                return (
                                    <div className='navbarr-bg'>
                                        <img src={item} alt=''  />
                                    </div>
                                )
                            })
                        } */}
                    </div>
                    {/* <div className="n-bg-l" >
                        <img src='' alt="" />
                    </div>
                    <div className="n-bg-r" >
                        <img src='' alt="" />
                    </div>
                    <div className="n-bg-r" >
                        <img src='' alt="" />
                    </div>
                    <div className="n-bg-r" >
                        <img src='' alt="" />
                    </div> */}

                    <div className="n-menu">

                        <ul>
                            <li onMouseEnter={()=> setSelectNavImg(Images[0])}>
                                <span>01</span>
                                <Link to='/'>Home</Link>
                            </li>
                            <li onMouseEnter={()=> setSelectNavImg(Images[0])}>
                                <span>02</span>
                                <Link to='/about'>Abouts us</Link>
                            </li>
                            <li onMouseEnter={()=> setSelectNavImg(Images[1])}>
                                <span>03</span>
                                <Link to='/product'>Products</Link>
                            </li>
                            <li onMouseEnter={()=> setSelectNavImg(Images[2])}>
                                <span>04</span>
                                <Link to='/contact'>Contact Us</Link>
                            </li >
                            <li onMouseEnter={()=> setSelectNavImg(Images[3])}>
                                <span>05</span>Innovation
                            </li>
                            <li onMouseEnter={()=> setSelectNavImg(Images[4])}>
                                <span>06</span>
                                <Link to='/events'>Events</Link>
                            </li>
                        </ul>


                    </div>
                </div>

                <div className="n-down">
                    <button className='circle-btn n-down-btn'>Smart Nav</button>

                </div>

            </div>
        </>


    )
}
/*
className='n-drop1'
<ol className='n-dropdown'>
                                <li>About NETSOL Technologies</li>
                                <li>Board of Directors</li>
                                <li>Management Team</li>
                                <li>Social Responsibilities</li>
                                  </ol> */

export default Navbar





