import React from 'react';
import './Contact.css';
import CONTACTBG from '../../img/contactvector.svg';
import { MenuBar } from '../MenuBar/MenuBar';
import Demo from '../Demo/Demo';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <>
            <MenuBar />
            <Demo />
            <div className="contact-wrappe">
                <div className="viewing">
                    You are now viewing <span className='viewing-span'><Link to='/'>Home</Link></span>  / <span className='viewing-span'><Link to='/contact'>Contact Us</Link></span>
                </div>
                <div className='contactBg'>
                    <img src={CONTACTBG} alt="" />
                </div>
                <div className="about-header contact-header">
                    <h1>Contact Us</h1>
                    <h5>
                        Please help us to respond better to
                        your query by filling out the relevant form.
                    </h5>
                </div>

                <div className="contact-forms">

                    <div className="cf-box">
                        <div className="cf-heading">
                            <h1>Request Demo Session</h1>
                            <h2><span className='abc'>01</span>. Enter your basic information</h2>
                        </div>
                        <div className="cf-items">
                            <form action="">
                                <input type="text" placeholder='Name' />
                                <input type="email" placeholder='Email' />
                                <input type="text" placeholder='Designation' />
                                <input type="text" placeholder='Company Name' />
                                <select>
                                    <option>Pakistan</option>
                                    <option>India</option>
                                    <option>Afganistan</option>
                                    <option>China</option>
                                </select>
                                <input type="text" placeholder='Phone' />

                                <div className="contact-btn">
                                    <button className="btn">PREVIOUS</button>
                                    <button className="btn">NEXT</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="cf-box">
                        <div className="cf-heading">
                            <h1>Contact Form</h1>
                            <h2><span className='abc'>01</span>. Enter your basic information</h2>
                        </div>
                        <div className="cf-items">
                            <form action="">
                                <input type="text" placeholder='Name' />
                                <input type="email" placeholder='Email' />
                                <input type="text" placeholder='Designation' />
                                <input type="text" placeholder='Company Name' />
                                <select>
                                    <option>Pakistan</option>
                                    <option>India</option>
                                    <option>Afganistan</option>
                                    <option>China</option>
                                </select>
                                <input type="text" placeholder='Phone' />

                                <div className="contact-btn">
                                    <button className="btn">PREVIOUS</button>
                                    <button className="btn">NEXT</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact