import './App.css';
import About from './Pages/About us/About';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './Pages/Main/Main';
import Product from './Pages/Products/Product';
import Events from './Pages/Events/Events';
import Contact from './Components/Contact/Contact';
import Explore from './Components/Explore/Explore';

function App() {
  return (
    <>
    
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/about' element={<About />} />
          <Route path='/product' element={<Product />} />
          <Route path='/events' element={<Events />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Explore' element={<Explore />} />
        </Routes>
      </BrowserRouter>
 
    </>
  );
}

export default App;
