import React from 'react';
import './Dated.css';
import Releases from '../../img/Releases.svg';
import Twitter from '../../img/Twitter.svg';
import Events from '../../img/Events.svg';

const Dated = () => {
    return (
        <>
            <div className="a-explore">

                <div className="explore-box">
                    <div className="explore-header">
                        <div className="explore-title">
                            <h3>Press Releases</h3>
                            <h4>Released Jun 17, 2021</h4>
                        </div>
                        <div className="explore-icon">
                            <img src={Releases} alt="" />
                        </div>
                    </div>
                    <div className="explore-desc">
                        <h5>
                            MINI USA and MINI Financial
                            Services Enable Dealers to
                            Facilitate End-to-End Online
                            Shopping Experience with Launch of
                        </h5>
                    </div>
                    <div className="explore-btns">
                        <button className="btn ex-btn">Explore</button>
                    </div>
                </div>

                <div className="explore-box">
                    <div className="explore-header">
                        <div className="explore-title">
                            <h3>From Twitter</h3>
                            <h4>Latest tweet from @netsoltech</h4>
                        </div>
                        <div className="explore-icon">
                            <img src={Twitter} alt="" />
                        </div>
                    </div>
                    <div className="explore-desc">
                        <h5>
                            Introducing NXT: a COVID-Aware Smart
                            WorkplaceReturning to your offices will
                            now be safe and fulfilling while ensuring
                        </h5>
                    </div>
                    <div className="explore-btns">
                        <button className="btn ex-btn">Explore</button>
                    </div>
                </div>

                <div className="explore-box">
                    <div className="explore-header">
                        <div className="explore-title">
                            <h3>Events</h3>
                            <h4>Where you can find us</h4>
                        </div>
                        <div className="explore-icon">
                            <img src={Events} alt="" />
                        </div>
                    </div>
                    <div className="explore-desc">
                        <h5>
                            Upcoming Event: Motor Finance Europe:
                            Conference & Awards 2022 On 20-10-2022
                        </h5>
                    </div>
                    <div className="explore-btns">
                        <button className="btn ex-btn">Explore</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Dated